import React, { FC, useEffect, useCallback, useRef, useState, useLayoutEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import { AdConfigDataProps } from '@sport1/types/web'
import useInViewport from '@/hooks/useInViewport'
import useScript from '@/hooks/useScript'
import { IJWEvent, IJWMedia, IJWPlayer } from '@/types/jwplayer'
import { Type } from '@/types/video'
import Config from '@/utils/Config'
import { debug } from '@/utils/Logger'
import { configureVast } from '@/utils/ads/vastConfig'
import { useConsentManager } from '@/utils/consent/ConsentInfoProvider'
import { MappedTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import normalizeVideoTrackingData from '@/utils/tracking/normalizeVideoTrackingData'
import { VideoPlaylistItem } from '@/helpers/videoHelper/prepareVideoPlaylist/types'
import attachAgfTracking from '@/utils/tracking/attachAgfTracking'
import getVideoLength from '@/utils/video/getVideoLength'

type Props = {
    ad?: AdConfigDataProps
    duration?: number
    id: string
    playlist: Array<IJWMedia>
    autostart?: boolean
    callback?: (jwPlayerInstance: IJWPlayer) => void
    trackingData?: MappedTrackingData
    type?: Type
    testID?: string
    hasRevolverPlay?: boolean
    setRunningVideo?: Function
    isArticleTopVideo?: boolean
}

type PlaybackReturnType =
    | 'autoplay'
    | 'play'
    | 'autoplay_interaction'
    | 'play_playerreco'
    | 'revolverplay'

export const playerUrl = `https://content.jwplatform.com/libraries/${Config.JW_PLAYER}.js`

/**
 * browsers may suppress autostarts; so check for autostart as well as
 * JWPlayer's playReason
 */
const videoPlayback = (
    autostart: boolean,
    event: IJWEvent,
    isRevolverInteraction = false
): PlaybackReturnType => {
    if (!event.playReason) {
        return autostart ? 'autoplay' : 'play'
    }
    if (event.playReason === 'related-interaction' || isRevolverInteraction)
        return 'play_playerreco'
    if (event.playReason === 'related-auto') return 'revolverplay'
    if (autostart) {
        // event 'playReason' on mobile is called 'viewable' and not 'autostart'
        if (event.playReason === 'autostart' || event.playReason === 'viewable') return 'autoplay'
        // external start after user scrolls
        if (event.playReason === 'external') return 'autoplay_interaction'
    }
    return 'play'
}

type DurationTrack = {
    [percentToBeTracked: string]: {
        tracked: boolean
        value: '25%' | '50%' | '75%' | '100%'
    }
}

/**
 * Returns true if we **think** the browser will only autostart when
 * muted. Likely to change often.
 */
const startsMuted = () => {
    try {
        return (
            typeof document === 'undefined' ||
            typeof location === 'undefined' ||
            typeof navigator === 'undefined' ||
            // coming from another site? audio autoplay won't work
            location.hostname !== new URL(document.referrer).hostname ||
            navigator.userAgent.includes('Firefox') ||
            // check if is Safari (Chrome also includes Safari in its
            // userAgent...)
            (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome'))
        )
    } catch (ignore) {
        return true
    }
}

const JWPlayer: FC<Props> = ({
    ad,
    duration,
    id,
    playlist,
    callback,
    autostart = false,
    trackingData,
    type,
    testID,
    setRunningVideo,
    hasRevolverPlay = false,
    isArticleTopVideo = false,
}) => {
    const { consentInfo } = useConsentManager()
    const player = useRef<IJWPlayer | null>(null)
    const initialSetup = useRef(false)
    const isPlayerInitialized = useRef(false)
    const currentVideoIndex = useRef(0)
    const floatingPlayerRightPosition = useRef<number | undefined>(0)

    useLayoutEffect(() => {
        const mainContentAndEdgeSpace = (window.innerWidth - 1040) / 2
        floatingPlayerRightPosition.current =
            mainContentAndEdgeSpace > 0 ? mainContentAndEdgeSpace : 0
    }, [])

    const percent = useRef(0)
    const durationsTracked = useRef({
        25: { tracked: false, value: '25%' },
        50: { tracked: false, value: '50%' },
        75: { tracked: false, value: '75%' },
        95: { tracked: false, value: '100%' }, // exaggerated
    } as DurationTrack)

    const autostartSuccessful = useRef(false)
    const ref = useRef<HTMLDivElement>(null)
    const isInViewPort = useInViewport(ref)

    const [isAutoStart, setAutoStart] = useState(consentInfo.consent_info === 'on' && autostart)
    const [isAutoStartMuted, setAutoStartMuted] = useState(startsMuted())
    const [isPlayingAd, setIsPlayingAd] = useState(true) // TODO Ströer please check the logic with the new informations
    const [playerLoaded, setPlayerLoaded] = useState(
        useScript(playerUrl, () => setPlayerLoaded(true))
    )
    const { trackIvwInfo, trackIvwView, trackInteraction } = useTracking()
    const [isPlayingBannerVideo, setIsPlayingBannerVideo] = useState(false)

    const getAdditionalTrackingData = useCallback(
        (playlistIndex = 0) => {
            if (playlist && playlist.length > 0) {
                const { file, tracking } = playlist[playlistIndex] as VideoPlaylistItem
                return normalizeVideoTrackingData({
                    autostart: isAutoStart,
                    trackingData: tracking ?? trackingData,
                    type,
                    videoUrl: file,
                })
            }
            return {}
        },
        [isAutoStart, trackingData, type, playlist]
    )

    /**
     * complete initialSetup. check for autostart options.
     */
    useEffect(() => {
        setAutoStartMuted(startsMuted())
        const consentAutoplay = consentInfo.consent_info === 'on' && autostart
        if (consentAutoplay && consentAutoplay !== isAutoStart) {
            setAutoStart(consentAutoplay)
            initialSetup.current = false
        }
    }, [autostart, consentInfo.consent_info, isAutoStart])

    const startAutoPlayOnScroll = useCallback(() => {
        if (isAutoStart && player.current && !autostartSuccessful.current && isInViewPort) {
            player.current.play()
            window.removeEventListener('scroll', startAutoPlayOnScroll)
        }
    }, [isAutoStart, isInViewPort])

    /**
     * Dark UX pattern.
     * The first interaction with the page shall trigger the autoplay.
     * This is done to trick(!) the browser to autostart with audio (€$¥₿).
     */
    useEffect(() => {
        window.addEventListener('scroll', startAutoPlayOnScroll)
        return () => window.removeEventListener('scroll', startAutoPlayOnScroll)
    }, [startAutoPlayOnScroll])

    useEffect(() => {
        if (initialSetup.current || !playerLoaded) {
            return
        }
        initialSetup.current = true
        configureVast({
            ad,
            type,
            duration,
            trackingData,
            callback: adConfig => {
                const config = {
                    playlist,

                    // only try the autostart when muted; if not muted we'll wait for an interaction
                    autostart: isAutoStart && isAutoStartMuted,
                    mute: (isAutoStart && isAutoStartMuted) || (isAutoStart && isArticleTopVideo),
                    ...(hasRevolverPlay && {
                        related: {
                            autoplaytimer: 5,
                            oncomplete: 'autoplay',
                        },
                    }),
                    sharing: {
                        sites: ['facebook', 'email', 'reddit'],
                    },
                    cast: {},
                    floating: {
                        dismissible: true,
                    },
                    ...adConfig,
                }
                debug('JWPlayer config', config)
                const jwPlayer = window.jwplayer(id).setup(config)
                player.current = jwPlayer

                jwPlayer.on('beforePlay', event => {
                    const playlistIndex = jwPlayer.getPlaylistIndex()
                    if (!hasRevolverPlay) {
                        if (playlistIndex === 1) {
                            setIsPlayingBannerVideo(true)
                        } else {
                            setIsPlayingBannerVideo(false)
                        }
                    }
                    const additionalTrackingData = getAdditionalTrackingData(
                        hasRevolverPlay ? playlistIndex : 0
                    )
                    if (event.playReason === 'interaction') {
                        trackIvwInfo({
                            ivw_context: 'jwplayer',
                            additionalTrackingData,
                        })
                        trackIvwView({
                            ivw_context: 'jwplayer',
                            additionalTrackingData,
                        })
                    }

                    if (!isPlayerInitialized.current) {
                        isPlayerInitialized.current = true
                        let isRevolverInteraction = false
                        if (hasRevolverPlay && currentVideoIndex.current !== playlistIndex) {
                            currentVideoIndex.current = playlistIndex
                            if (event.playReason === 'interaction') {
                                isRevolverInteraction = true
                            }
                        }
                        trackInteraction({
                            interaction_action: 'initialisierung',
                            interaction_category: 'video',
                            video_playback: videoPlayback(
                                isAutoStart,
                                event,
                                isRevolverInteraction
                            ),
                            ...additionalTrackingData,
                        })
                    }
                })

                jwPlayer.on('complete', () => {
                    isPlayerInitialized.current = false
                    percent.current = 0
                    Object.values(durationsTracked.current).forEach(tracking => {
                        tracking.tracked = false
                    })
                })

                jwPlayer.on('adPlay', event => {
                    window.dispatchEvent(new Event('videoIsPlaying'))
                    setIsPlayingAd(true)
                    trackInteraction({
                        interaction_action: 'ad_played',
                        interaction_category: 'video',
                        video_playback: videoPlayback(isAutoStart, event),
                        ...getAdditionalTrackingData(
                            hasRevolverPlay ? jwPlayer.getPlaylistIndex() : 0
                        ),
                    })
                })

                jwPlayer.on('adComplete', event => {
                    trackInteraction({
                        interaction_action: 'ad_completed',
                        interaction_category: 'video',
                        video_playback: videoPlayback(isAutoStart, event),
                        ...getAdditionalTrackingData(
                            hasRevolverPlay ? jwPlayer.getPlaylistIndex() : 0
                        ),
                    })
                })

                jwPlayer.on('play', event => {
                    if (isAutoStart) {
                        autostartSuccessful.current = true
                    }

                    if (isPlayingAd) {
                        setIsPlayingAd(false)
                    }
                    window.dispatchEvent(new Event('videoIsPlaying'))
                    trackInteraction({
                        interaction_action: 'video_played',
                        interaction_category: 'video',
                        video_playback: videoPlayback(isAutoStart, event),
                        ...getAdditionalTrackingData(
                            hasRevolverPlay ? jwPlayer.getPlaylistIndex() : 0
                        ),
                    })
                })

                if (hasRevolverPlay && setRunningVideo) {
                    jwPlayer.on('playlistItem', event => {
                        isPlayerInitialized.current = false
                        const { index } = event
                        const newRunningVideo = playlist[index!] as VideoPlaylistItem
                        attachAgfTracking({
                            ivwCode: newRunningVideo.tracking.page_ivw_code,
                            length: getVideoLength(newRunningVideo),
                            player: jwPlayer,
                            title: newRunningVideo.agfTitle,
                            type: 'VoD',
                            videoId: newRunningVideo.id,
                            ...newRunningVideo?.agfMatch,
                        })
                        setRunningVideo(newRunningVideo)
                    })
                }

                jwPlayer.on('error', event => {
                    trackInteraction({
                        interaction_category: 'error_player',
                        interaction_label: event.sourceError.error.message?.substring(0, 254),
                        interaction_action: `${event.code}`,
                        page_name: hasRevolverPlay
                            ? playlist[jwPlayer.getPlaylistIndex()].file
                            : playlist?.[0].file,
                    })

                    Sentry.captureException(
                        new Error(
                            `JWPlayer - Message: ${event.message}, Detail: ${event.sourceError.details}, Error: ${event.sourceError.error.message}, Code: ${event.code}`
                        )
                    )
                })

                jwPlayer.on('pause', event => {
                    trackInteraction({
                        interaction_action: 'video_paused',
                        interaction_category: 'video',
                        video_playback: videoPlayback(isAutoStart, event),
                        ...getAdditionalTrackingData(
                            hasRevolverPlay ? jwPlayer.getPlaylistIndex() : 0
                        ),
                    })
                })

                jwPlayer.on('time', event => {
                    if (!event.position || !Number.isFinite(event.duration)) {
                        return // livestreams don't track this
                    }

                    const p = Math.round((event.position / (event.duration as number)) * 100)
                    if (Number.isNaN(p) || percent.current === p) {
                        return
                    }

                    /**
                     * track every 25%
                     */
                    Object.entries(durationsTracked.current).forEach(
                        ([percentToBeTracked, tracking]) => {
                            if (!tracking.tracked && p >= Number(percentToBeTracked)) {
                                tracking.tracked = true
                                trackInteraction({
                                    interaction_action: tracking.value,
                                    interaction_category: 'videodauer',
                                    video_playback: videoPlayback(isAutoStart, event),
                                    ...getAdditionalTrackingData(
                                        hasRevolverPlay ? jwPlayer.getPlaylistIndex() : 0
                                    ),
                                })
                            }
                        }
                    )

                    percent.current = p
                })

                if (callback) {
                    callback(jwPlayer)
                }
            },
        })
    }, [
        ad,
        duration,
        callback,
        id,
        isAutoStart,
        isAutoStartMuted,
        isPlayingAd,
        playerLoaded,
        playlist,
        trackInteraction,
        trackIvwInfo,
        trackIvwView,
        type,
        trackingData,
        setRunningVideo,
        hasRevolverPlay,
        getAdditionalTrackingData,
        isArticleTopVideo,
    ])

    return (
        <div ref={ref} data-testid={testID}>
            <div id={id} className={id} />
            <style jsx global>{`
                body .jwplayer.jw-state-error,
                body .jwplayer.jw-flag-aspect-mode {
                    aspect-ratio: 16 / 9;
                }
                .jw-state-error .jw-aspect.jw-reset {
                    padding-top: 0 !important;
                }
                .jw-icon-fullscreen {
                    display: ${isPlayingAd ? 'none !important' : ''};
                }
                .jw-icon-next {
                    display: none;
                }
                .jw-controlbar {
                    visibility: ${isPlayingBannerVideo ? 'hidden !important' : ''};
                }
                .jw-flag-floating .jw-wrapper {
                    right: ${floatingPlayerRightPosition.current}px !important;
                    max-width: 336px !important;
                }
                .jw-slider-horizontal.jw-chapter-slider-time {
                    align-items: end !important;
                }
                .jwplayer.jw-flag-ads .jw-controlbar .jw-slider-time .jw-slider-container {
                    height: 3px !important;
                }
                @media (max-width: 768px) {
                    .jw-flag-floating .jw-wrapper {
                        display: none;
                    }
                }
            `}</style>
        </div>
    )
}

export default JWPlayer
